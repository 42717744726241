import { AppHomeScreenSections } from "fetchers/app-home-screen";
import { Venue } from "fetchers/venue";
import * as React from "react";
import { UiState } from "services/api";
import { getAppHomeScreenSections } from "services/appHomeScreenApi";

export const AppSectionsContext = React.createContext<AppSectionsContextProps>({});

export type VenueSections = Map<string | number, AppHomeScreenSections>;

export type AppSectionsContextProps = {
  state?: UiState<VenueSections>;
  selectedVenue?: Venue;
  setSelectedVenue?: (venue: Venue) => void;
  loadSections?: (venueId: string | number, forceRefresh?: boolean) => void;
  updateSections?: (venueId: string | number, sections: AppHomeScreenSections) => void;
};

type Props = {
  selectedVenue?: Venue;
  children: React.ReactNode;
};

export const AppSectionsContextProvider: React.FC<Props> = (props) => {
  const loadSections = (venueId: string | number, forceRefresh?: boolean) => {
    if (forceRefresh || !state.data?.get(venueId)) {
      setState({
        ...state,
        isLoading: true,
      });
      getAppHomeScreenSections(venueId)
        .then((response) => {
          setState((prev) => {
            const updatedData = new Map(prev.data ?? []);
            updatedData.set(venueId, response.data);

            return {
              ...prev,
              isLoading: false,
              data: updatedData,
            };
          });
        })
        .catch((err) => {
          console.error(err);
          setState({
            ...state,
            isLoading: false,
            error: err.message,
          });
        });
    }
  };

  const updateSections = (venueId: string | number, sections: AppHomeScreenSections) => {
    setState((prev) => {
      const updatedData = new Map(prev.data ?? []);
      updatedData.set(venueId, sections);

      return {
        ...prev,
        data: updatedData,
      };
    });
  };

  const [state, setState] = React.useState<UiState<VenueSections>>({ isLoading: false, data: new Map() });
  const [selectedVenue, setSelectedVenue] = React.useState<Venue | undefined>(props.selectedVenue);

  return (
    <AppSectionsContext.Provider
      value={{
        state: state,
        selectedVenue: selectedVenue,
        setSelectedVenue: setSelectedVenue,
        loadSections,
        updateSections,
      }}
    >
      {props.children}
    </AppSectionsContext.Provider>
  );
};
