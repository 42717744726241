import * as React from "react";

import { cloneDeep } from "lodash";
import { Venue } from "fetchers/venue";
import Masonry from "react-masonry-css";
import Card from "components/common/Card";
import CardHeader from "./CardHeader";
import Status from "components/form/Status";
import Title from "components/common/Title";
import InfoTooltip from "components/common/InfoTooltip";
import Bubbles from "components/MenuCardsBubbles";
import { Category, Menu } from "fetchers/menu";
import EditCreateMenuDrawer from "components/EditCreateMenu";
import { useMenusStore } from "stores/menusStore";
import { UiState } from "services/api";
import { TOOLTIP_MENU_CATEGORIES, TOOLTIP_MENU_TYPE } from "constants/strings";
import Button from "components/form/Button";
import { Printer } from "react-feather";

type Props = {
  venue: Venue;
  uiState: UiState<Menu[]>;
};

type State = Menu | null;

const Menus: React.FC<Props> = (props) => {
  const { venue, uiState } = props;
  const [state] = useMenusStore();

  const [isMenuDrawerVisible, setIsMenuDrawerVisible] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState<State>(null);

  React.useEffect(() => {
    setSelectedMenu((prev) => {
      const updatedMenu = state.data?.find((item) => item.id == prev?.id);
      return updatedMenu ?? null;
    });
  }, [state]);

  const handleCloseModal = () => {
    setSelectedMenu(null);
    setIsMenuDrawerVisible(false);
  };

  const getCategories = (categories: Category[]) => {
    return categories.map((category) => {
      return { text: category.title };
    });
  };

  const handleSelectedMenu = (index: number) => {
    if (!state.data) {
      return;
    }

    setSelectedMenu(cloneDeep(state.data[index]));
    setIsMenuDrawerVisible(true);
  };

  const handlePrintMenu = (venueId: string | number, menuId: string | number) => {
    // history.push("/print-menu/" + venueId + "/" + menuId)
    const win = window.open("/print-menu/" + venueId + "/" + menuId, "_blank");
    win?.focus();
  };

  return (
    <>
      {uiState.isLoading && <div>Loading...</div>}
      {uiState.error && <div>{uiState.error}</div>}
      {state.data?.length ? (
        <Masonry
          breakpointCols={{
            default: 4,
            1300: 3,
            950: 2,
            640: 1,
          }}
          className="masonry-grid"
          columnClassName="masonry-grid-column"
        >
          {state.data.map((menu: Menu, index: number) => (
            <Card key={menu.id} onClick={() => handleSelectedMenu(index)} className="cursor-pointer hover:shadow-m">
              <CardHeader title={menu.name} isVisible={menu.visible} />

              <Status isVisible={menu.visible} className="mb-2.5" />

              {/* TODO: add items images */}
              {/* <Images images={menu.images} /> */}

              <div className="mb-2.5">
                <Title size="medium" className="mb-2.5">
                  <span className="mr-2">Venue</span>
                </Title>
                <Bubbles bubblesClassName="bg-ice-150" bubbles={[{ text: venue.name }]} />
              </div>

              <div className="mb-2.5">
                <Title size="medium" className="mb-2.5">
                  <span className="mr-2">Type</span>
                  <InfoTooltip title={TOOLTIP_MENU_TYPE} />
                </Title>
                <Bubbles bubblesClassName="bg-orchid-10" bubbles={[{ text: menu.type }]} />
              </div>

              <div className="mb-2.5">
                <Title size="medium" className="mb-2.5">
                  <span className="mr-2">Categories</span>
                  <InfoTooltip title={TOOLTIP_MENU_CATEGORIES} />
                </Title>
                <Bubbles bubblesClassName="bg-blue-30" bubbles={getCategories(menu.categories)} />
              </div>
              <Button
                color="orchid"
                size="small"
                type="outline"
                className="mt-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrintMenu(menu.venueId, menu.id);
                }}
              >
                Print
                <Printer size={24} className="ml-5" />
              </Button>
            </Card>
          ))}
        </Masonry>
      ) : (
        !uiState.isLoading && !uiState.error && <p className="w-full">There is no menu yet for this venue.</p>
      )}
      {selectedMenu && isMenuDrawerVisible && (
        <EditCreateMenuDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          menu={selectedMenu}
          isNew={false}
          isVisible={isMenuDrawerVisible}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Menus;
