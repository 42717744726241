import * as React from "react";
import { Plus } from "react-feather";

import Button from "components/form/Button";
import DrawerWithButton, { DrawerRef } from "components/common/DrawerWithButton";
import Banner from "./BannerSection/index";
import Menus from "./Menus";
import Highlights from "./Highlights/index";

type Props = {
  buttonSize?: "large" | "small";
};

const CreateAppSection: React.FC<Props> = (props) => {
  const drawerRef = React.createRef<DrawerRef>();

  const ModalButton =
    props.buttonSize === "small" ? (
      <Button type="icon-only" color="green" className="rounded-2lg" raised>
        <Plus size={22} />
      </Button>
    ) : (
      <Button className="fixed bottom-0 right-0 mr-6 mb-6" type="icon-only" color="green" size="large" raised>
        <Plus size={42} />
      </Button>
    );

  return (
    <DrawerWithButton
      title="Choose section"
      button={ModalButton}
      style={{ transform: "none !important" }}
      ref={drawerRef}
    >
      <div className="grid md:grid-cols-3 md:gap-6 px-7 pt-7 pb-5">
        <Menus onClose={() => drawerRef.current?.close()} />
        <Highlights onClose={() => drawerRef.current?.close()} />
        <Banner onClose={() => drawerRef.current?.close()} />
      </div>
    </DrawerWithButton>
  );
};

CreateAppSection.defaultProps = {
  buttonSize: "small",
};

export default CreateAppSection;
