import * as React from "react";
import { Form as AntForm, FormInstance } from "antd";

import Settings from "./Settings";
import Structure from "./Structure";
import { Menu, Subcategory } from "fetchers/menu";

type Props = {
  menu?: Menu;
  form: FormInstance<any>;
  venueId?: string | null;
  isNew?: boolean;
  onFormFinish: (menu: Menu) => void;
  closeDrawer?: () => void;
  onDelete?: () => void;
};

const MenuForm: React.FC<Props> = (props) => {
  const { form } = props;
  const [isVenueSelectionDisabled, setIsVenueSelectionDisabled] = React.useState(!props.isNew);

  const initialValues = {
    ...(props.menu as Menu),
  };

  // Refresh form data if props changes. This happens after modifying a product and getting updated menu.
  React.useEffect(() => {
    form.setFieldsValue(props.menu);
  }, [props.menu]);

  const hasAnyItems = (): boolean => {
    const menu = props.form.getFieldsValue(true) as Menu;
    const itemsCount = menu.categories.reduce(
      (prev, currCat) => prev + currCat.items.length + subcategoriesItemCount(currCat.subcategories),
      0
    );
    return itemsCount > 0;
  };

  const subcategoriesItemCount = (subcategories: Subcategory[]): number => {
    if (subcategories && Array.isArray(subcategories)) {
      return subcategories.reduce((prev2, currSub) => prev2 + currSub.items.length, 0);
    } else {
      return 0;
    }
  };

  return (
    <AntForm
      className="bg-primary-white flex-1 px-8 pt-8 flex flex-col h-full"
      form={form}
      initialValues={initialValues}
      onFinish={props.onFormFinish}
    >
      <Settings isNew={props.isNew} venueId={props.menu?.venueId} isVenueSelectionDisabled={isVenueSelectionDisabled} />
      <Structure
        form={form}
        onItemsUpdated={() => {
          setIsVenueSelectionDisabled(!props.isNew || hasAnyItems());
        }}
      />
    </AntForm>
  );
};

export default MenuForm;
