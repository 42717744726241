import { MenuItem } from "fetchers/menuItem";
import * as React from "react";
import { formatCurrency } from "utils/currency_utils";
import { joinStringByComma } from "utils/string_utils";
import { ComposableElement, ComposableElementType } from "./models";

type Props = {
  elements: ComposableElement[];
  pageRef: React.RefObject<HTMLDivElement>;
};

const PagePreview: React.FC<Props> = (props) => {
  const { elements, pageRef } = props;

  return (
    <div className="print-menu-page-a4" ref={pageRef}>
      {elements.map((element, index) => {
        switch (element.type) {
          case ComposableElementType.MENU_TITLE:
            return (
              <p id={`${index}`} key={`${index}`} className="print-menu-title">
                {element?.data}
              </p>
            );

          case ComposableElementType.CATEGORY_TITLE:
            return (
              <p id={`${index}`} key={`${index}`} className="print-category-title">
                {element?.data}
              </p>
            );

          case ComposableElementType.SUBCATEGORY_TITLE:
            return (
              <p id={`${index}`} key={`${index}`} className="print-subcategory-title ">
                {element?.data}
              </p>
            );

          case ComposableElementType.PRODUCT: {
            const item = element.data as MenuItem;

            return (
              <div id={`${index}`} key={`${index}`} className="print-menu-product-container">
                <div className="flex justify-between">
                  <p className="print-product-title-price">{item?.title}</p>
                  <div className="print-dashed-line" />
                  <p className="print-product-title-price">{formatCurrency(parseInt(item?.price))}</p>
                </div>

                <p className="print-product-description">{item?.description}</p>
                {item.allergens && item.allergens.length > 0 && (
                  <p className="print-allergens">
                    <span>{joinStringByComma(item.dietary)}</span>
                    {item.allergens.length > 0 && item.allergens.length > 0 && (
                      <span className="mr-4 ml-4 text-sm text-black-op-20">&#8226;</span>
                    )}
                    <span>
                      <b>Allergens:</b> {joinStringByComma(item.allergens)}
                    </span>
                  </p>
                )}
              </div>
            );
          }

          case ComposableElementType.SPACE:
            return <div id={`${index}`} style={{ height: `${element.data}px` }} key={`${index}`} />;
        }
      })}
    </div>
  );
};

PagePreview.displayName = "PagePreview";

export default PagePreview;
