import { parseInt } from "lodash";
import * as React from "react";
import { ComposableElement, ComposableElementType } from "./models";
import PagePreview from "./PagePreview";

type Props = {
  elements: ComposableElement[];
};

const MenuPrintPreview = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { elements } = props;
  const [pageMap, setPageMap] = React.useState<Map<number, ComposableElement[]> | null>(null);
  const pageRef = React.useRef<HTMLDivElement>(null);
  const [distributed, setDistributed] = React.useState(false);

  // Initialize map by placing all elements to first page
  React.useEffect(() => {
    if (pageMap == null && elements.length > 0) {
      const map = new Map();
      map.set(0, elements);
      setPageMap(map);
    }
  }, [elements]);

  React.useEffect(() => {
    if (distributed) {
      return;
    }

    const container = ref?.current;

    if (container) {
      const pageContainer = container.querySelector(".print-menu-page-a4");

      if (pageContainer) {
        const paddingTop = window.getComputedStyle(pageContainer).paddingTop;
        const paddingBottom = window.getComputedStyle(pageContainer).paddingBottom;
        const containerHeight = pageContainer.clientHeight - (parseFloat(paddingTop) + parseFloat(paddingBottom));

        let totalHeight = 0;
        let items: ComposableElement[] = [];
        let page = 0;
        const updatedMap = new Map<number, ComposableElement[]>();

        // Calculate heights and set index
        const elementsUpdated = elements.map((element, index) => {
          const elementHeight: number = parseInt(
            container.querySelector(`#${CSS.escape(index.toString())}`).clientHeight
          );

          return {
            index: index,
            height: elementHeight,
            ...element,
          };
        });

        elementsUpdated.forEach((element) => {
          const elementHeight = element.height ?? 0;

          if (totalHeight + elementHeight > containerHeight) {
            // If the page is full, close this page and start a new page

            // Apply following rules
            // 1. Last item of the page should be PRODUCT.
            // 2. First item of the page shouldn't be SPACE

            let top = items[items.length - 1];
            const itemsToCarry: ComposableElement[] = [];

            while (top.type != ComposableElementType.PRODUCT) {
              const poppedElement = items.pop();
              if (poppedElement) {
                itemsToCarry.push(poppedElement);
                top = items[items.length - 1];
              }
            }

            // Close the page and start a new page
            updatedMap?.set(page, items);
            page++;
            items = [];
            totalHeight = 0;

            if (itemsToCarry.length > 0) {
              // First item of the page shouldn't be SPACE, pop it
              if (itemsToCarry[itemsToCarry.length - 1].type == ComposableElementType.SPACE) {
                itemsToCarry.pop();
              }

              while (itemsToCarry.length > 0) {
                const poppedElement = itemsToCarry.pop();
                if (poppedElement) {
                  totalHeight += poppedElement.height ?? 0;
                  items.push(poppedElement);
                }
              }
            }

            // Push to new page
            // First item of the page shouldn't be SPACE, unnecessary
            if (element.type != ComposableElementType.SPACE) {
              totalHeight += elementHeight;
              items.push(element);
            }
          } else {
            // Push to current page
            totalHeight += elementHeight;
            items.push(element);
          }
        });

        if (items.length > 0) {
          updatedMap?.set(page, items);
        }

        setPageMap(updatedMap);
        setDistributed(true);
      }
    }
  }, [pageMap]);

  return (
    <div className="print-menu" ref={ref}>
      {pageMap &&
        Array.from(pageMap.keys()).map((key: number) => {
          return <PagePreview key={key} elements={pageMap.get(key) ?? []} pageRef={pageRef} />;
        })}
    </div>
  );
});

MenuPrintPreview.displayName = "MenuPrintPreview";

export default MenuPrintPreview;
