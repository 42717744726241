import cx from "classnames";
import { Form, FormInstance } from "antd";
import * as React from "react";
import { Edit } from "react-feather";

import Field from "components/form/Field";
import Avatar from "components/common/Avatar";
import UploadModal from "components/UploadModal";

type Props = {
  imageUri?: string;
  form?: FormInstance;
  avatarClassName?: string;
  imgClassName?: string;
  avatarStyle?: React.CSSProperties;
  actionEndpoint: string;
  inputName?: string;
  aspectRatio: number;
  size?: "big" | "small" | "medium" | "large" | "x-large" | "xx-large" | "xxx-large" | "free";
};

const Thumbnail: React.FC<Props> = (props) => {
  const [imageUri, setImageUri] = React.useState<string | undefined>(props.imageUri);
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.imageUri != imageUri) {
      setImageUri(props.imageUri);
    }
  }, [props.imageUri]);

  return (
    <>
      <div className="relative cursor-pointer group" onClick={() => setVisible(true)}>
        <Avatar
          style={props.avatarStyle}
          size={props.size}
          src={imageUri}
          className={cx("flex", props.avatarClassName)}
          imgClassName={cx("object-cover", props.imgClassName)}
        />
        <div
          className={cx(
            "absolute top-0 left-0 bottom-0 right-0 bg-black",
            "flex items-center justify-center",
            "opacity-0 group-hover:opacity-50",
            "transition-opacity delay-75",
            "rounded-xl",
            {
              "w-96 h-54 rounded-6lg overflow-hidden": props.size === "xxx-large", // 388px x 216px
              "w-48 h-64 rounded-6lg overflow-hidden": props.size === "xx-large", // 192px x 256px
              "w-24 h-32 rounded-3lg overflow-hidden": props.size === "x-large", //  96px x 128px
              "w-15 h-20 rounded-2lg overflow-hidden": props.size === "large", //  60px x 80px
              "w-9 h-12 rounded-2lg overflow-hidden": props.size === "medium", //  36px x 48px
              "w-7.5 h-10 rounded-2lg overflow-hidden": props.size === "small", //  30px x 40px
            }
          )}
        >
          <Edit color="white" />
        </div>
        <Form.Item name={props.inputName} hidden>
          <Field type="hidden" />
        </Form.Item>
      </div>
      <UploadModal
        modalProps={{ visible }}
        aspectRatio={props.aspectRatio}
        draggerProps={{
          filename: "imageURI",
          action: props.actionEndpoint,
        }}
        onSave={(imageUri) => {
          setImageUri(imageUri);
          props.form?.setFieldsValue({
            imageUri,
          });
          setVisible(false);
        }}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

Thumbnail.defaultProps = {
  inputName: "imageUri",
};

export default Thumbnail;
