import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "pages/App";
import Menus from "./pages/Menus";
import Login from "./pages/Login";
import UIKit from "./pages/UI-Kit";
import Products from "./pages/Products";
import Layout from "./components/Layout";
import Help from "./pages/Help";
import Loading from "./components/common/Loading";
import PrintMenu from "pages/PrintMenu/PrintMenu";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/ui-kit" component={UIKit} />
          <Route exact path="/print-menu/:venueId/:menuId" component={PrintMenu} />
          <Layout>
            <Route exact path="/" component={Menus} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/app" component={App} />
            {/* <Route exact path="/settings" component={Settings} /> */}
            {/* <Route exact path="/towers" component={Towers} /> */}
            {/* <Route exact path="/customers" component={Customers} /> */}
            {/* <Route exact path="/live-activity" component={LiveActivity} /> */}
            <Route exact path="/help" component={Help} />
          </Layout>
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Routes;
