import { MenuItem } from "fetchers/menuItem";

export type ComposableElement = {
  type: ComposableElementType;
  data: string | MenuItem | number;
  index?: number;
  height?: number;
};

export enum ComposableElementType {
  MENU_TITLE,
  CATEGORY_TITLE,
  SUBCATEGORY_TITLE,
  PRODUCT,
  SPACE,
}
