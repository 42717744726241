import * as React from "react";

import { Venue } from "fetchers/venue";
import Menus from "./Menus";
import EditCreateMenuDrawer from "components/EditCreateMenu";
import { useMenusStore } from "stores/menusStore";

type Props = {
  venue: Venue;
  isMenuDrawerVisible: boolean;
  onCloseMenuDrawer: () => void;
};

const MenuAccordionContent: React.FC<Props> = (props) => {
  const [state, actions] = useMenusStore();

  React.useEffect(() => {
    actions.fetchData(props.venue.id);
  }, []);

  return (
    <>
      <Menus venue={props.venue} uiState={state} />
      {props.isMenuDrawerVisible && (
        <EditCreateMenuDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          isNew={true}
          isVisible={props.isMenuDrawerVisible}
          onClose={props.onCloseMenuDrawer}
        />
      )}
    </>
  );
};

export default MenuAccordionContent;
