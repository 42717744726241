import { Menu } from "fetchers/menu";
import { createStore, createHook, Action } from "react-sweet-state";
import { UiState } from "services/api";
import { getMenus, sortAlphabetical } from "services/menuApi";

const initialState: UiState<Menu[]> = {
  isLoading: false,
  data: [],
};

const actions = {
  fetchData: (venueId?: string | number): Action<UiState<Menu[]>> => ({ setState }) => {
    if (!venueId) {
      setState({
        isLoading: false,
        error: "Missing venue id.",
      });
      return;
    }

    setState({
      isLoading: true,
    });

    getMenus(venueId)
      .then((response) => {
        setState({
          isLoading: false,
          data: response.data,
        });
      })
      .catch((err) => {
        setState({
          isLoading: false,
          error: err.message,
        });
      });
  },
  addItem: (item: Menu): Action<UiState<Menu[]>> => ({ setState, getState }) => {
    setState({
      data: sortAlphabetical([...(getState().data ?? []), item]),
    });
  },
  deleteItem: (item: Menu): Action<UiState<Menu[]>> => ({ setState, getState }) => {
    setState({
      data: sortAlphabetical((getState().data ?? []).filter((currentItem) => currentItem.id != item.id)),
    });
  },
  updateItems: (items: Menu[]): Action<UiState<Menu[]>> => ({ setState }) => {
    setState({
      data: sortAlphabetical(items),
    });
  },
};

const MenusStore = createStore({
  initialState: initialState,
  actions: actions,
  name: "MenusStore",
});

export const useMenusStore = createHook(MenusStore);
