import { Menu } from "fetchers/menu";
import * as React from "react";
import { useParams } from "react-router-dom";
import { getMenu } from "services/menuApi";
import ReactToPrint from "react-to-print";
import { ComposableElement, ComposableElementType } from "./models";
import MenuPrintPreview from "./MenuPrintPreview";
import { Printer } from "react-feather";
import Button from "components/form/Button";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Icon from "components/icons/Icon";
import cx from "classnames";
import { useReactToPrint } from "react-to-print";

type Params = {
  venueId: string;
  menuId: string;
};

const PrintMenu: React.FC = () => {
  const { venueId, menuId } = useParams<Params>();
  const [menu, setMenu] = React.useState<Menu | undefined>();
  const componentRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    getMenu(venueId, menuId)
      .then((response) => {
        setMenu(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const mapToComposableElements = (menu: Menu | undefined): ComposableElement[] => {
    const elements: ComposableElement[] = [];

    if (!menu) {
      return elements;
    }

    // Step 1: Add menu title
    if (menu.name) {
      elements.push({ type: ComposableElementType.MENU_TITLE, data: menu.name });
    }

    // Step 2: Add space between Categories and Menu Title
    if (menu.categories.length > 0) {
      elements.push({ type: ComposableElementType.SPACE, data: 32 });
    }

    menu.categories.forEach((category, index) => {
      // Step 3: Add space above Categories except the first one
      if (index != 0) {
        elements.push({ type: ComposableElementType.SPACE, data: 32 });
      }

      // Step 4: Add Category Title
      elements.push({ type: ComposableElementType.CATEGORY_TITLE, data: category.title });

      // Step 5: Space between Category Title and Items
      if (category.items.length > 0) {
        elements.push({ type: ComposableElementType.SPACE, data: 32 });
      }

      category.items.forEach((item, index) => {
        // Step 6: Add space above items except the first one
        if (index != 0) {
          elements.push({ type: ComposableElementType.SPACE, data: 16 });
        }

        // Step 7: Add Product
        elements.push({ type: ComposableElementType.PRODUCT, data: item });
      });

      // Step 8: Add space between Subcategories and Items
      if (category.subcategories.length > 0) {
        elements.push({ type: ComposableElementType.SPACE, data: 16 });
      }

      category.subcategories.forEach((subcategory, index) => {
        // Step 9: Add space above subcategories except the first one
        if (index != 0) {
          elements.push({ type: ComposableElementType.SPACE, data: 16 });
        }

        // Step 10: Add Subcategory Title
        elements.push({ type: ComposableElementType.SUBCATEGORY_TITLE, data: subcategory.title });

        // Step 11: Space between Subcategory Title and Items
        if (category.items.length > 0) {
          elements.push({ type: ComposableElementType.SPACE, data: 32 });
        }

        subcategory.items.forEach((item, index) => {
          // Step 12: Add space above items except the first one
          if (index != 0) {
            elements.push({ type: ComposableElementType.SPACE, data: 16 });
          }

          // Step 13: Add Product
          elements.push({ type: ComposableElementType.PRODUCT, data: item });
        });
      });
    });

    return elements;
  };

  return (
    <>
      <Layout hasSider>
        <Sider width={300} className="print-menu-sidebar p-4">
          <div className="flex items-center">
            <Icon name="LogoIcon" className="text-5xl" />
            <Icon name="LogoTextIcon" wrapper={false} className={cx("ml-5 text-2xl w-24")} />
            <Icon name="CrossIcon" className="w-8 h-8 text-black text-lg ml-auto md:hidden" />
          </div>

          {<p className="mt-24">To print your menu, click the button below.</p>}
          {
            <p className="mt-4">
              We recommend using Google Chrome for best results. Designs might appear different or distorted in other
              browsers.
            </p>
          }

          <Button color="black" size="large" className="mt-6" onClick={handlePrint}>
            Print Menu
            <Printer size={24} className="ml-5" />
          </Button>
        </Sider>

        <Layout className="site-layout" style={{ marginLeft: 300 }}>
          <Content style={{ paddingTop: "32px", paddingBottom: "32px", overflow: "initial", background: "#f5f5f5" }}>
            <ReactToPrint content={() => componentRef.current} />

            <MenuPrintPreview ref={componentRef} elements={mapToComposableElements(menu)} />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default PrintMenu;
