import * as React from "react";

import Drawer from "components/common/Drawer";
import { Item } from "fetchers/item";
import ProductDetails from "./ProductDetails";
import DrawerHeader from "pages/Menus/DrawerHeader";
import { PRODUCT_DETAILS_DRAWER_TITLE } from "constants/strings";
import { DrawerProps } from "antd";
import { Edit } from "react-feather";
import Button from "components/form/Button";
import EditCreateProductDrawer from "../EditCreateProduct";

type Props = DrawerProps & {
  item?: Item;
  isVisible: boolean;
  onClose?: () => void;
};

/**
 * Read-only Item/Product Details Drawer.
 *
 * @param props
 * @returns
 */
const ProductDetailsDrawer: React.FC<Props> = (props) => {
  const { item, isVisible, onClose, ...restProps } = props;
  const [isEditItemDrawerVisible, setIsEditItemDrawerVisible] = React.useState(false);

  const showEditDrawer = () => {
    setIsEditItemDrawerVisible(true);
    props.onClose && props.onClose();
  };

  return (
    <>
      <Drawer
        style={{ transform: "none !important" }}
        height="100vh"
        width="35%"
        placement="right"
        onClose={onClose}
        visible={isVisible}
        key={item?.id}
        footer={
          <Button
            size="large"
            color="black"
            className="float-right"
            onClick={() => {
              showEditDrawer();
            }}
          >
            Edit
            <Edit className="ml-5" />
          </Button>
        }
        title={
          <DrawerHeader
            hideOverflowMenu={true}
            isNew={false}
            isVisible={item?.visible}
            title={PRODUCT_DETAILS_DRAWER_TITLE}
          />
        }
        {...restProps}
      >
        <ProductDetails item={item} />
      </Drawer>
      {item && (
        <EditCreateProductDrawer
          style={{ transform: "none !important" }}
          item={item}
          destroyOnClose={true}
          isNew={false}
          isVisible={isEditItemDrawerVisible}
          onClose={() => setIsEditItemDrawerVisible(false)}
        />
      )}
    </>
  );
};

export default ProductDetailsDrawer;
