import { DrawerProps } from "antd";
import { Form as AntForm } from "antd";
import Drawer from "components/common/Drawer";
import {
  ERROR_MENU_DELETED,
  ERROR_SOMETHING_WENT_WRONG,
  NEW_MENU,
  SELECT_VENUE_WARNING,
  SUCCESS_MENU_DELETED,
} from "constants/strings";
import DrawerHeader from "pages/Menus/DrawerHeader";
import * as React from "react";
import ModalDelete from "components/ModalDelete";
import { Menu } from "fetchers/menu";
import message from "components/common/message";
import { VENUE_MENUS_POST, VENUE_MENUS_PATCH } from "constants/endpoints";
import Button from "components/form/Button";
import { CheckCircle, Trash2 } from "react-feather";
import MenuForm from "pages/Menus/menu/Form";
import { deleteMenu, saveMenu } from "services/menuApi";
import { useMenusStore } from "stores/menusStore";

type Props = DrawerProps & {
  menu?: Menu;
  isNew?: boolean;
  isVisible: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
};

const EditCreateMenuDrawer: React.FC<Props> = (props) => {
  const { menu, isNew, isVisible, onClose, ...restProps } = props;
  const [form] = AntForm.useForm();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(props.menu?.visible ?? true);

  const [, actions] = useMenusStore();

  const handleDelete = () => {
    setShowDeleteModal(false);

    if (props.isNew) {
      return;
    }

    if (!menu?.venueId || !menu?.id) {
      return;
    }

    setIsLoading(true);

    deleteMenu(menu)
      ?.then(() => {
        actions.deleteItem && props.menu && actions.deleteItem(props.menu);

        message({
          type: "success",
          content: SUCCESS_MENU_DELETED,
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
        props.onClose && props.onClose();
      })
      .catch((err) => {
        console.error(err);
        message({
          type: "warning",
          content: ERROR_MENU_DELETED,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleSave = (data: Menu) => {
    let endpoint;

    if (isLoading) {
      setIsLoading(false);
    }

    if (!data?.venueId || !data?.categories) {
      message({
        type: "warning",
        content: !data?.categories ? "Please add a category." : SELECT_VENUE_WARNING,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (props.isNew) {
      endpoint = VENUE_MENUS_POST(data.venueId);
    } else if (props.menu?.id) {
      endpoint = VENUE_MENUS_PATCH(data.venueId, props.menu.id);
    } else {
      message({
        type: "warning",
        content: "Menu doesn't exist id: " + props.menu?.id,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    setIsLoading(true);

    data.visible = isMenuVisible;

    data?.categories.forEach((category, index) => {
      category.displaySequence = index;
      if (category.items?.length) {
        category.items.forEach((item, index) => {
          item.displaySequence = index;
          item.imageUri = undefined;
        });
      } else {
        category.items = [];
      }
      if (category.subcategories?.length) {
        category.subcategories.forEach((subcategory, index) => {
          subcategory.displaySequence = index;
          subcategory.items.forEach((item, index) => {
            item.displaySequence = index;
            item.imageUri = undefined;
          });
        });
      } else {
        category.subcategories = [];
      }
    });

    saveMenu(endpoint, props.isNew ? "POST" : "PATCH", data)
      .then((response) => {
        actions.updateItems && actions.updateItems(response.data);

        message({
          type: "success",
          content: props.isNew ? "Menu has been created successfully." : "Menu has been updated successfully.",
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
        props.onClose && props.onClose();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Drawer
        onClose={() => {
          form.resetFields();
          onClose && onClose();
        }}
        visible={isVisible}
        key={menu?.id}
        title={
          <DrawerHeader
            isNew={isNew}
            title={props.menu ? props.menu.name : NEW_MENU}
            isVisible={isMenuVisible}
            onVisibilityChange={(isVisible) => setIsMenuVisible(isVisible)}
          />
        }
        footer={
          <div className="flex gap-6 justify-between">
            <Button
              size="large"
              color="red"
              htmlType="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={props.isNew}
            >
              Delete
              <Trash2 size={24} className="ml-10" />
            </Button>

            <Button size="large" color="black" onClick={() => form.submit()} htmlType="submit">
              Publish
              <CheckCircle size={24} className="ml-10" />
            </Button>
          </div>
        }
        {...restProps}
      >
        <MenuForm
          menu={menu}
          isNew={isNew}
          form={form}
          onFormFinish={handleSave}
          closeDrawer={() => onClose && onClose()}
          onDelete={() => setShowDeleteModal(true)}
        />
      </Drawer>
      <ModalDelete
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onOk={handleDelete}
      />
    </>
  );
};

EditCreateMenuDrawer.displayName = "ProductDetailsDrawer";

export default EditCreateMenuDrawer;
